/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js
 * - /npm/@hotwired/turbo@7.1.0/dist/turbo.es2017-umd.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.19.3/dist/additional-methods.min.js
 * - /npm/form-request-submit-polyfill@2.0.0/form-request-submit-polyfill.min.js
 * - /npm/sticky-table-headers@0.1.24/js/jquery.stickytableheaders.min.js
 * - /npm/daterangepicker@3.1.0/moment.min.js
 * - /npm/hammerjs@2.0.8/hammer.min.js
 * - /npm/vanilla-lazyload@17.6.1/dist/lazyload.min.js
 * - /npm/sweetalert2@11.4.4/dist/sweetalert2.all.min.js
 * - /npm/izitoast@1.4.0/dist/js/iziToast.min.js
 * - /npm/izimodal-1.6.0@1.6.1/js/iziModal.min.js
 * - /npm/bootstrap-timepicker@0.5.2/js/bootstrap-timepicker.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/select2.min.js
 * - /npm/swiper@8.0.6/swiper-bundle.min.js
 * - /npm/inputmask@5.0.7/dist/inputmask.min.js
 * - /npm/datatables.net@1.10.25/js/jquery.dataTables.min.js
 * - /npm/datatables.net-bs5@1.10.25/js/dataTables.bootstrap5.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
